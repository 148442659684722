<template>
  <div class="map-search">
    <div class="section-search">
      <perfect-scrollbar>
        <hr class="m-0" />
        <div class="p-4">
          <span class="fontsize-h6">ข้อมูลพื้นที่ระบบประปาหมู่บ้าน</span>
          <div class="fontsize-sm font-reddanger pt-1">
            *เลือกชั้นข้อมูลที่ต้องการเรียกดู จากนั้นกดปุ่ม ค้นหา
          </div>
          <div class="wrapper mt-3">
            <input
              type="radio"
              name="select"
              id="option-2"
              class="displayNone"
              :value="2"
              v-model="selected"
            />
            <input
              type="radio"
              name="select"
              id="option-3"
              class="displayNone"
              :value="3"
              v-model="selected"
            />
            <label
              for="option-2"
              class="
                option option-2
                d-flex
                justify-content-between
                align-items-center
                py-2
                px-4
              "
            >
              <div class="d-flex align-items-center">
                <div class="dot"></div>
                <span class="ml-2">ประปาหมู่บ้าน</span>
              </div>
              <iconify
                icon="ic:round-water-drop"
                class="fontsize-h2 float-right icon-color"
              />
            </label>
            <label
              for="option-3"
              class="
                option option-3
                d-flex
                justify-content-between
                align-items-center
                py-2
                px-4
              "
            >
              <div class="d-flex align-items-center">
                <div class="dot"></div>
                <span class="ml-2">ประปาถ่ายโอน</span>
              </div>
              <iconify
                icon="fluent:table-move-right-24-filled"
                class="fontsize-h2 float-right icon-color"
              />
            </label>
          </div>

          <b-overlay :show="isLoad">
            <button class="btn-check w-100 mt-3" @click="searchMap">
              <font-awesome-icon icon="search" class="fontsize-h2 pr-2" />ค้นหา
            </button>
          </b-overlay>
        </div>
        <hr class="m-0" />
        <div class="p-4">
          <span class="fontsize-h6">เลือกชั้นการแสดงผลข้อมูลแหล่งน้ำ</span>
          <div class="form-check ml-3 mt-1">
            <b-form-checkbox
              type="checkbox"
              v-model="select1"
              class="form-check-input cursor-pointer"
              id="typeCheck1"
              @change="filterSelect"
            >
              <span class="cursor-pointer" for="typeCheck1"
                >แหล่งน้ำผิวดิน</span
              >
            </b-form-checkbox>
          </div>
          <div class="form-check ml-3 mt-1">
            <b-form-checkbox
              type="checkbox"
              v-model="select2"
              class="form-check-input cursor-pointer"
              id="typeCheck2"
              @change="filterSelect"
            >
              <span class="cursor-pointer" for="typeCheck2">แหล่งน้ำบาดาล</span>
            </b-form-checkbox>
          </div>
          <div class="form-check ml-3 mt-1">
            <b-form-checkbox
              type="checkbox"
              v-model="select3"
              class="form-check-input cursor-pointer"
              id="typeCheck3"
              @change="filterSelect"
            >
              <span class="cursor-pointer" for="typeCheck3">ดูทั้งหมด</span>
            </b-form-checkbox>
          </div>
        </div>
        <hr class="m-0" />
        <div v-if="selected !== null && selected === 1">
          <div class="p-4">
            <span class="fontsize-h6">สัญลักษณ์การแสดงผลข้อมูลแหล่งน้ำ</span>

            <div class="mt-2 ml-3">
              <img
                src="/images/icon-ground.png"
                alt="Left image"
                class="img-size"
              />
              <span class="font-darkgray ml-3">แหล่งน้ำผิวดิน</span>
            </div>
            <div class="mt-2 ml-3">
              <img
                src="/images/icon-badaln.png"
                alt="Left image"
                class="img-size"
              />
              <span class="font-darkgray ml-3">แหล่งน้ำบาดาล</span>
            </div>
          </div>
          <hr class="m-0" />
          <div class="p-4">
            <span class="fontsize-h6 d-flex align-items-center"
              >ข้อมูลพื้นที่คุณภาพระบบประปาหมู่บ้าน
            </span>
            <br />
            <perfect-scrollbar class="scrollbar-custom">
              <span class="font-darkgray">
                ข้อมูลคุณภาพระบบประปาหมู่บ้านเป็นข้อมูลที่กรมทรัพยากรน้ำได้ดำเนินการรวบรวมผลจากการจัดทำแบบประเมินคุณภาพระบบประปาหมู่บ้านทั่วประเทศ
              </span>
            </perfect-scrollbar>
          </div>
        </div>
        <div v-if="selected !== null && selected === 2">
          <div class="p-4">
            <span class="fontsize-h6 d-flex align-items-center"
              >ข้อมูลพื้นที่ระบบประปาหมู่บ้าน
            </span>
            <br />
            <span>
              ข้อมูลประปาหมู่บ้านมีแหล่งข้อมูลจากการทำแบบสำรวจเพื่อจัดทำข้อมูลโครงสร้างพื้นฐาน
              (ระบบประปา) INFO ของกรมส่งเสริมการปกครองท้องถิ่น (ข้อมูล ณ ปี
              2562)
            </span>
          </div>
        </div>
        <div v-if="selected !== null && selected === 3">
          <div class="p-4">
            <span class="fontsize-h6 d-flex align-items-center"
              >ข้อมูลพื้นที่ประปาถ่ายโอน
            </span>
            <br />
            <span>
              ข้อมูลประปาถ่ายโอนเป็นข้อมูลระบบประปาหมู่บ้านที่กรมทรัพยากรน้ำ
              ดำเนินการถ่ายโอนให้องค์กรปกครองส่วนท้องถิ่น
            </span>
          </div>
        </div>
        <hr class="m-0" />
        <div class="p-4">
          <router-link :to="{ name: 'Login' }" target="_blank"
            ><u>ทางเข้าสู่ระบบสำหรับเจ้าหน้าที่ สบจ.</u></router-link
          >
        </div>
      </perfect-scrollbar>
    </div>
    <div class="section-map">
      <gmap-map
        class="custom-map-container"
        ref="refGmap"
        :center="center"
        :zoom="zoom"
        :options="{
          gestureHandling: 'greedy',
          scaleControl: true,
          streetViewControl: false,
        }"
      >
        <gmap-cluster
          :gridSize="50"
          :zoomOnClick="true"
          :enableRetinaIcons="true"
          :minimumClusterSize="3"
          ref="cluster"
          @click="clusteredMarkers($event)"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            @click="toggleInfoWindow(m, index)"
            :icon="{
              url: m.icon,
              scaledSize: { width: 30, height: 30, f: 'px', b: 'px' },
            }"
          >
          </gmap-marker>
        </gmap-cluster>

        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <div v-html="infoContent"></div>
        </gmap-info-window>
      </gmap-map>
    </div>
  </div>
</template>

<script>
import { markers1, markers2, markers3 } from "./mock-data.js";
import { MapService } from "@/services/main.service.js";
import infoType1 from "./infoType1.vue";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

export default {
  name: "map-search",
  components: {
    infoType1,
    GmapCluster,
  },
  data() {
    return {
      isLoad: false,
      center: { lat: 13.736717, lng: 100.523186 },
      zoom: 6,
      map: null,
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      dataSource: [],
      markers: [],
      select1: false,
      select2: false,
      select3: false,
      mapSearch: null,
      marker: {},
      showInfo: false,
      selected: null,
      apiURL: process.env.VUE_APP_BASE_API_QUALITY,
      apiGIS: process.env.VUE_APP_BASE_API_DOWNLOAD,
    };
  },
  created() {
    this.selected = null;
  },
  mounted() {
    this.$refs.refGmap.$mapPromise.then((map) => {
      map.panTo({ lat: 13.736717, lng: 100.523186 });
    });
  },
  methods: {
    geoCurrentLocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    clusteredMarkers(event) {},
    getInfoWindowContent: function (marker) {
      console.log("marker ,", marker);
      if (marker.searchTypeID === 1) {
        let barClass = "bg-success";
        let fontClass = "font-green";
        let qualityText = "ผ่าน";
        let iconQuality = "correct.png";
        if (marker.qualityStatus === 2) {
          barClass = "bg-danger";
          iconQuality = "cross.png";
          fontClass = "font-reddanger";
          qualityText = "ไม่ผ่าน";
        }
        let typeName = "";
        let productionRate = "";
        if (marker.pwaTypeId === 1) {
          typeName = "ระบบประปาผิวดิน อัตราการผลิต : ";
          productionRate = marker.productionRate + " ลบ.ม/ชม.";
        } else {
          typeName = "ประเภทระบบประปาบาดาล : ";
          productionRate = marker.waterType;
        }

        let imageQuality = "";
        if (marker.qualityPointSymbol === "A") {
          imageQuality = "Excellent.png";
        } else if (marker.qualityPointSymbol === "B") {
          imageQuality = "Good.png";
        } else if (marker.qualityPointSymbol === "C") {
          imageQuality = "Enough.png";
        } else if (marker.qualityPointSymbol === "D") {
          imageQuality = "So.png";
        } else if (marker.qualityPointSymbol === "F") {
          imageQuality = "Bad.png";
        }

        let filePath = "-";
        if (marker.qualityDocument !== null && marker.qualityDocument !== "") {
          filePath = `<img src="/images/pdf1.png" style="height: 19px" /><a
              href="${this.apiURL}${marker.qualityDocument}" target="_blank"
            >
              ไฟล์เอกสาร</a
            >`;
        }

        return `<div class="custom-info-window">
        <div class="d-flex fontsize-h6">
          <div>
            <img src="/images/eva_pin-fill.png" style="height: 19px" />
          </div>
          <div class="ml-1">${marker.locationName}</div>
          <div class="font-gray ml-1" style="font-family: "Sarabun" !important;">(ข้อมูลคุณภาพน้ำ)</div>
        </div>
        <div class="font-darkgray mt-1">
          สถานที่ตั้ง :
          <span class="font-black"
            >${marker.locationAddress}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          ${typeName}
          <span class="font-black">${productionRate}</span>
        </div>
        <div class="font-darkgray mt-1">
          หน่วยงานที่สร้าง :
          <span class="font-black">${marker.departmentBuildName}</span>
        </div>
        <div class="font-darkgray mt-1">
          ปีงบประมาณ :
          <span class="font-black">${marker.inYear}</span>
        </div>
        <div class="font-darkgray mt-1">
          ผลการวิเคราะห์คุณภาพน้ำ :
          <span class="${fontClass} weight-700"
            >${qualityText}
              <img
        class="header-logo-img"
        src="/images/${iconQuality}"
        style="height: 10px;"
      />
            </span>
        </div>
        <div class="font-darkgray mt-1">
          เอกสารผลการวิเคราะห์คุณภาพน้ำ :
          <span class="font-green weight-700"
            >${filePath}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          มาตรฐานคุณภาพระบบประปาหมู่บ้าน :
          <span class="weight-700"
            ><img
              src="/images/${imageQuality}"
              style="height: 15px; margin-right: 2px"
            />${marker.qualityPointText}</span
          >
        </div>
        <div class="mt-1">
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              style="width: ${marker.qualityPointPercent}%; background-color: ${marker.qualityPointColor}"
              aria-valuenow="${marker.qualityPoint}"
              aria-valuemin="0"
              aria-valuemax="5"
            ></div>
          </div>
        </div>
        <div class="mt-1 d-flex justify-content-between">
          <div>คะแนน ${marker.qualityPoint} คิดเป็น ${marker.qualityPointPercent}%</div>
          <div class="font-darkgray text-right">ข้อมูลวันที่ ${marker.dateUpdatedStr}</div>
        </div>
      </div>`;
      } else if (marker.searchTypeID === 2) {
        let filePath = "-";
        if (!!marker.projectPicture) {
          filePath = `<img src="/images/img-icon.png" style="height: 19px" /><a
              href="${this.apiGIS}${marker.projectPicture}" target="_blank"
            >
              ดูรูปภาพ</a
            >`;
        }
        if (marker.waterName === null) {
          marker.waterName = "ไม่ระบุ";
        }
        if (marker.locationAddress === null) {
          marker.locationAddress = "";
        }
        if (marker.owner === null) {
          marker.owner = "";
        }
        if (marker.sourceTypeName === null) {
          marker.sourceTypeName = "";
        }
        if (marker.capacity === null) {
          marker.capacity = "";
        }
        if (marker.householdReceivingWater === null) {
          marker.householdReceivingWater = 0;
        }
        if (marker.createYear === null) {
          marker.createYear = "";
        }

        return `<div class="custom-info-window">
        <div class="d-flex fontsize-h6">
          <div>
            <img src="/images/eva_pin-fill.png" style="height: 19px" />
          </div>
          <div class="ml-1">${marker.locationName}</div>
        </div>
        <div class="font-darkgray mt-1">
          สถานที่ตั้ง :
          <span class="font-black"
            >${marker.locationAddress}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          เจ้าของกรรมสิทธิ์ทรัพย์สิน :
          <span class="font-black"
            >${marker.owner}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          ประเภทประปา :
          <span class="font-black"
            >${marker.sourceTypeName}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          อัตราการผลิต :
          <span class="font-black"
            >${marker.capacity}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          จำนวนครัวเรือนรับน้ำ :
          <span class="font-black"
            >${marker.householdReceivingWater}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          ปี พศ ที่ก่อสร้างเสร็จ :
          <span class="font-black"
            >${marker.createYear}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          รูปภาพแหล่งประปา :
          <span class="font-green weight-700"
            >${filePath}</span
          >
        </div>
        </div>`;
      } else if (marker.searchTypeID === 3) {
        let filePath = "-";
        if (!!marker.documentTransfer) {
          filePath = `<img src="/images/pdf1.png" style="height: 19px" /><a
              href="${this.apiGIS}${marker.documentTransfer}" target="_blank"
            >
              ไฟล์เอกสาร</a
            >`;
        }
        return `<div class="custom-info-window">
        <div class="d-flex fontsize-h6">
          <div>
            <img src="/images/eva_pin-fill.png" style="height: 19px" />
          </div>
          <div class="ml-1">${marker.locationName}</div>
          <div class="font-gray ml-1">(ประปาถ่ายโอน)</div>
        </div>
        <div class="font-darkgray mt-1">
          สถานที่ตั้ง :
          <span class="font-black"
            >${marker.locationAddress}</span
          >
        </div>
        <div class="font-darkgray mt-1">
          ประเภทประปา :
          <span class="font-black">${marker.sourceTypeName}</span>
        </div>
        <div class="font-darkgray mt-1">
          กำลังการผลิต :
          <span class="font-black">${marker.productionRate}</span>
        </div>
        <div class="font-darkgray mt-1">
          หน่วยงานที่โอนให้ :
          <span class="font-black">${marker.departmentTransferName}</span>
        </div>
        <div class="font-darkgray mt-1">
          สถานภาพ :
          <span class="font-black">${marker.statusText}</span>
        </div>
        <div class="font-darkgray mt-1">
          ปีที่ถ่ายโอน :
          <span class="font-black">${marker.inYearTransfer}</span>
        </div>
        <div class="font-darkgray mt-1">
          หน่วยงานที่สร้าง :
          <span class="font-black">${marker.departmentBuildName}</span>
        </div>
        <div class="font-darkgray mt-1">
          ปีงบประมาณ :
          <span class="font-black">${marker.inYear}</span>
        </div>
        <div class="font-darkgray mt-1">
          สังกัด สทภ. :
          <span class="font-black">${marker.departmentName}</span>
        </div>
        <div class="font-darkgray mt-1">
          เอกสารการถ่ายโอน :
          <span class="font-green weight-700"
            >${filePath}</span
          >
        </div>
      </div>`;
      }
    },
    async callServiceSearchMap(param) {
      let previous = false;
      let next = false;
      this.dataSource = [];
      await MapService.searchMap(param)
        .then((res) => {
          if (res) {
            previous = res.hasPrevious;
            next = res.hasNext;
            let iconPic = "/images/eva_pin-fill.png";
            res.data.forEach((m) => {
              if (!(!m.latitude || !m.longitude)) {
                if (m.typeID === 1) {
                  iconPic = "/images/icon-ground.png";
                } else if (m.typeID === 2) {
                  iconPic = "/images/icon-badal.png";
                } else {
                  iconPic = "/images/eva_pin-fill.png";
                }
                m["position"] = {
                  lat: m.latitude,
                  lng: m.longitude,
                };
                m["icon"] = iconPic;

                this.dataSource.push(m);
              }
            });
            this.isLoad = false;
          }
        })
        .catch((err) => {
          this.isLoad = false;
          this.dataSource = [];
          this.markers = [];
        });

      if (previous === false) {
        this.$refs.refGmap.$mapPromise.then((map) => {
          map.zoom = this.zoom;
          map.panTo({ lat: 13.736717, lng: 100.523186 });
        });
      }

      return next;
    },
    async searchMap() {
      this.markers = [];
      this.infoWinOpen = false;

      if (!this.selected) {
        return;
      }

      this.isLoad = true;
      let hasNext = true;
      let num = 1;

      let param = {
        searchTypeID: this.selected,
        NumRow: 1000,
        NumPage: num,
      };

      while (hasNext) {
        hasNext = await this.callServiceSearchMap(param);
        param.NumPage = num += 1;
      }
    },
    filterSelect() {
      this.infoWinOpen = false;
      if (this.select1 && this.select2 && !this.select3) {
        this.markers = this.dataSource.filter(
          (f) => f.typeID === 1 || f.typeID === 2
        );
      } else if (this.select1 && !this.select2 && !this.select3) {
        this.markers = this.dataSource.filter((f) => f.typeID === 1);
      } else if (!this.select1 && this.select2 && !this.select3) {
        this.markers = this.dataSource.filter((f) => f.typeID === 2);
      } else {
        this.markers = this.dataSource;
      }
    },
  },
  watch: {
    dataSource: {
      handler: function (val, oldVal) {
        if (this.select1 && this.select2 && !this.select3) {
          this.markers = this.dataSource.filter(
            (f) => f.typeID === 1 || f.typeID === 2
          );
        } else if (this.select1 && !this.select2 && !this.select3) {
          this.markers = this.dataSource.filter((f) => f.typeID === 1);
        } else if (!this.select1 && this.select2 && !this.select3) {
          this.markers = this.dataSource.filter((f) => f.typeID === 2);
        } else {
          this.markers = this.dataSource;
        }
      },
    },
    deep: true,
  },
};
</script>

<style lang="scss" scoped>
$section-search-width: 365px;
.map-search {
  height: calc(100vh - #{$header-height-sm});
}

.section-search {
  width: $section-search-width;
  background-color: white;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: $header-height-sm;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.section-map {
  position: relative;
}

.custom-map-container {
  position: absolute;
  right: 0;
  width: calc(100% - #{$section-search-width});
  height: calc(100vh - #{$header-height-sm});
}

.gm-style-iw .gm-style-iw-c {
  font-family: "Sarabun" !important;
  .gm-style-iw-d {
    background-color: rebeccapurple;

    .custom-info-window {
      background-color: rebeccapurple;

      .font {
        font-family: "Sarabun" !important;

        &-so {
          color: #f1b263;
        }
        &-good {
          color: #b38ecb;
        }
        &-enough {
          color: #72b6d5;
        }
      }
    }
  }
}
</style>
