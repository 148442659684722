<template>
  <b-popover
    tabindex="1"
    ref="popoverInfo1"
    :target="`marker${marker.id}`"
    triggers="focus"
  >
    <template #title>Danger!</template>
    Danger variant popover
  </b-popover>
</template>

<script>
export default {
  props: {
    marker: {
      type: Object,
      default: () => {},
    },
    showPopover: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isShow: false,
    };
  },
  watch: {
    marker: {
      handler: function (val, oldVal) {
        if (val !== null) {
          console.log("marker : ", val.id);
        }
      },
    },
    deep: true,
  },
};
</script>

<style></style>
